import React from "react";

const ValueComponent = React.memo((props) => {
  const { columns, operators, filter, index, handleFilterChange } = props;

  const dataTypes =
    columns?.find(({ field }) => field === filter.field)?.type ?? "string";
  const selectedColumn = columns.find((col) => col.field === filter.field);
  const additionalOperators = selectedColumn?.additionalOperators || [];

  const allOperators = [...operators[dataTypes], ...additionalOperators];
  const selectedOperator = allOperators.find(
    (op) => op.value === filter.operator
  );
  if (!selectedOperator) {
    return null;
  }
  //const { InputComponent } = selectedOperator;

  if (
    ((selectedOperator?.type || selectedColumn?.type) &&
      (selectedOperator?.type === "select" ||
        selectedOperator?.type === "multiSelect")) ||
    selectedColumn?.type === "select" ||
    selectedColumn?.type === "multiSelect"
  ) {
    const options = selectedColumn?.valueOptions || [];
    return (
      <select
        className="bg-white border-b border-neutral-300 focus:border-neutral-700 w-[130px] text-sm"
        value={filter.value}
        onChange={(e) => handleFilterChange(index, "value", e.target.value)}
      >
        <option value="">Select value</option>
        {options.map((option, idx) => (
          <option key={idx} value={option[selectedColumn?.value]}>
            {option[selectedColumn?.label]}
          </option>
        ))}
      </select>
    );
  }
  return (
    <input
      className="border-b border-neutral-300 focus:border-neutral-100 w-[180px] text-sm h-5"
      type={filter.type}
      placeholder="Filter Value"
      value={filter.value}
      onChange={(e) => handleFilterChange(index, "value", e.target.value)}
    />
  );
});

export default ValueComponent;
