import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { toast } from "react-toastify";
import { InputField } from "../../components";
import Loading from "../../components/Loader/Loading";
export default function AddUpdateForm({
  handleModal,
  fetchData,
  isLoading,
  setIsLoading,
  modalType,
  reasons,
}) {
  const [hold_reason, setHoldReason] = useState("");

  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (modalType === "edit") {
      setHoldReason(reasons.hold_reason);
    } else if (modalType === "new") {
      setHoldReason("");
    }
  }, [modalType, reasons]);
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Form validation
    const errors = {};

    if (!hold_reason.trim()) {
      errors.hold_reason = "Please enter the reson.";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setIsLoading(false);
      return;
    } else {
      setErrors({});
    }

    const reasonData = {
      hold_reason,
    };

    if (modalType === "new") {
      api
        .post(
          `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/hold_reasons`,
          reasonData
        )
        .then(() => {
          handleModal();
          fetchData();
          toast.success("New Reason Created!", { autoClose: 3000 });
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (modalType === "edit" && reasons.id) {
      api
        .put(
          `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/hold_reasons/${reasons?.id}`,
          {
            ...reasonData,
          }
        )
        .then(() => {
          handleModal();
          fetchData();
          toast.success("Reason updated successfully!", { autoClose: 3000 });
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div>
      {isLoading && <Loading />}
      <div className="grid md:grid-cols-1 gap-4 w-full">
        <InputField
          label="Reason"
          name="hold_reason"
          placeholder="Enter Reason"
          value={hold_reason}
          onChange={(e) => setHoldReason(e.target.value)}
          error={errors.hold_reason}
          required={true}
        />
      </div>
      <div className="flex items-cente justify-end mt-5 space-x-2 text-white">
        <button
          onClick={handleModal}
          type="button"
          className="btnPrimary bg-red-500"
        >
          Cancel
        </button>

        {isLoading ? (
          <div className="bg-blue/10 flex items-center justify-center rounded-md py-2 w-48">
            <img src="/img/loading.gif" className="w-7" alt="" />
          </div>
        ) : (
          <button
            type="button"
            onClick={handleSubmit}
            className="btnPrimary bg-green-600"
          >
            Save and Update
          </button>
        )}
      </div>
    </div>
  );
}
