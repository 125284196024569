import React from "react";

export default function Status({ type }) {
  return (
    <>
      {(type === "notAssign" || type === "needToStart") && (
        <div className="py-2 px-2 text-xs rounded-full w-36 font-bold bg-yellow-100 text-yellow-700 text-center">
          {type === "notAssign" && "Not Assigned"}
          {type === "needToStart" && "Need To Start"}
        </div>
      )}
      {type === "assigned" && (
        <div className="py-2 px-2 text-xs rounded-full w-36 font-bold bg-green-100 text-green-600 text-center">
          Assigned
        </div>
      )}
      {(type === "success" || type === "completed") && (
        <div className="py-2 px-2 text-xs rounded-full w-36 font-bold bg-green-100 dark:bg-green-500/20 dark:text-green-300 text-green-600 text-center">
          Completed
        </div>
      )}
      {type === "pending" && (
        <div className="py-2 px-2 text-xs rounded-full w-36 font-bold bg-red-100 dark:bg-red-500/20 text-red-500 dark:text-red-400 text-center">
          Pending
        </div>
      )}
      {type === "inProcess" && (
        <div className="py-2 px-2 text-xs rounded-full w-36 font-bold bg-indigo-100 dark:bg-indigo-500/20 text-indigo-500 dark:text-indigo-400 text-center">
          In Process
        </div>
      )}
      {type === "inReview" && (
        <div className="py-2 px-2 text-xs rounded-full w-36 font-bold bg-indigo-100 dark:bg-indigo-500/20 text-indigo-500 dark:text-indigo-400 text-center">
          In Review
        </div>
      )}
      {type === "hold" && (
        <div className="py-2 px-2 text-xs rounded-full w-36 font-bold bg-gray-200 dark:bg-gray-500/20 text-gray-600 dark:text-gray-300 text-center">
          On Hold
        </div>
      )}
      {type === "cancelled" && (
        <div className="py-2 px-2 text-xs rounded-full w-36 font-bold bg-gray-200 dark:bg-gray-500/20 text-red-500 dark:text-gray-300 text-center">
          Cancelled
        </div>
      )}
       {type === "reOpen" && (
        <div className="py-2 px-2 text-xs rounded-full w-36 font-bold bg-gray-200 dark:bg-gray-500/20 text-indigo-500 dark:text-indigo-400 text-center">
          Reopen
        </div>
      )}
    </>
  );
}
