import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { toast } from "react-toastify";
import { InputField } from "../../components";
import Loading from "../../components/Loader/Loading";
export default function AddUpdateForm({
  handleModal,
  fetchData,
  isLoading,
  setIsLoading,
  modalType,
  products,
}) {
  const [niche_category, setCategory] = useState("");

  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (modalType === "edit") {
      setCategory(products.niche_category);
    } else if (modalType === "new") {
      setCategory("");
    }
  }, [modalType, products]);
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Form validation
    const errors = {};

    if (!niche_category.trim()) {
      errors.niche_category = "Please enter the product.";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setIsLoading(false);
      return;
    } else {
      setErrors({});
    }

    const payloadData = {
      niche_category,
    };

    if (modalType === "new") {
      api
        .post(
          `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/niche_products`,
          payloadData
        )
        .then(() => {
          handleModal();
          fetchData();
          toast.success("New Product Created!", { autoClose: 3000 });
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (modalType === "edit" && products.id) {
      api
        .put(
          `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/niche_products/${products?.id}`,
          {
            ...payloadData,
          }
        )
        .then(() => {
          handleModal();
          fetchData();
          toast.success("Product updated successfully!", { autoClose: 3000 });
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div>
      {isLoading && <Loading />}
      <div className="grid md:grid-cols-1 gap-4 w-full">
        <InputField
          label="Product Name"
          name="niche_category"
          placeholder="Enter Name"
          value={niche_category}
          onChange={(e) => setCategory(e.target.value)}
          error={errors.niche_category}
          required={true}
        />
      </div>
      <div className="flex items-cente justify-end mt-5 space-x-2 text-white">
        <button
          onClick={handleModal}
          type="button"
          className="btnPrimary bg-red-500"
        >
          Cancel
        </button>

        <button
          type="button"
          onClick={handleSubmit}
          className="btnPrimary bg-green-600"
        >
          Save and Update
        </button>
      </div>
    </div>
  );
}
