import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../../components/Loader/Loading";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
import api from "../../../services/api";
import TaskListHeader from "./TaskListHeader";
import TaskListCard from "./TaskListCard";

const BacklinkPerformanceReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tasks, setTasksList] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [users, setUsers] = useState([]);
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "AND",
    rules: [
      {
        field: "task_status",
        op: "eq",
        data: "hold",
      },
    ],
  });
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 1,
  });

  const initalData = {
    task_status: "hold",
    with_static_ip: false,
  };
  const [selectedPayload, setSelectedPayload] = useState(initalData);

  const fetchTasksList = async (filterData) => {
    const payload = {
      ...filterData,
    };
    setIsLoading(true);
    const url = `/api/report/backlinks_performance_report`;
    try {
      const response = await api.post(url, payload);
      if (response.status !== 200) {
        toast.error(response?.data?.message);
        setIsLoading(false);
        return;
      }
      setTasksList(response.data);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTasksList({
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      with_static_ip: false,
      filter: queryOptions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExpandClick = async (backlink_id) => {
    setIsLoading(true);
    setExpandedRows({});
    setTasksList((prev) => ({
      ...prev,
      data: prev.data.map((item) => ({
        ...item,
        isChild: false,
      })),
    }));
    const shouldExpand = !expandedRows[backlink_id];

    if (shouldExpand) {
      setTasksList((prev) => ({
        ...prev,
        data: prev.data.map((item) =>
          item.backlink_id === backlink_id ? { ...item, isChild: true } : item
        ),
      }));
      setExpandedRows((prev) => ({ ...prev, [backlink_id]: true }));
    }

    setIsLoading(false);
  };
  const handleTopPageChange = (page) => {
    setPaginationModel((prevState) => ({
      ...prevState,
      page: page + 1,
    }));
    fetchTasksList({
      page: +page + 1,
      pageSize: paginationModel.pageSize,
      filter: queryOptions,
      with_static_ip: selectedPayload?.with_static_ip,
    });
  };

  const handleTopPageSizeChange = (newPageSize) => {
    setPaginationModel((prevState) => ({
      ...prevState,
      page: 1,
      pageSize: newPageSize,
    }));
    fetchTasksList({
      page: 1,
      pageSize: newPageSize,
      filter: queryOptions,
      with_static_ip: selectedPayload?.with_static_ip,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    const filters = {
      groupOp: "AND",
      rules: [
        { field: "is_active", op: "eq", data: 1 },
      ],
    };
    api
      .post(`${process.env.REACT_APP_PUBLIC_API}/api/users/users_report`, {
        filters,
        page: 1,
        perPage: 100,
      })
      .then((response) => {
        const capitalizedUsers = response.data?.records.map((user) => {
          return {
            ...user,
            name: user.name.replace(/\b\w/g, (c) => c.toUpperCase()),
          };
        });
        setUsers(capitalizedUsers);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  return (
    <div className="p-6 px-2 ">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Backlinks Performance Report", link: "" },
        ]}
      />
      {isLoading && <Loading />}
      <ToastContainer />
      <TaskListHeader
        isLoading={isLoading}
        tasks={tasks}
        setExpandedRows={setExpandedRows}
        selectedPayload={selectedPayload}
        setSelectedPayload={setSelectedPayload}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        handleTopPageChange={handleTopPageChange}
        handleTopPageSizeChange={handleTopPageSizeChange}
        fetchTasksList={fetchTasksList}
        users={users}
        setQueryOptions={setQueryOptions}
        queryOptions={queryOptions}
      />

      <div className="my-2 rounded ">
        {tasks?.data?.length ? (
          tasks?.data.map((item, index) => (
            <div
              className={`${
                index === tasks?.data.length - 1
                  ? "border-none"
                  : "border-b border-gray-100 "
              }`}
            >
              <TaskListCard
                item={item}
                expandedRows={expandedRows}
                handleExpandClick={handleExpandClick}
              />
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500 py-44">
            No records found
          </div>
        )}
      </div>
    </div>
  );
};

export default BacklinkPerformanceReport;
