import React, { useState, useEffect, useCallback } from "react";
import MultiSelectBox from "../../components/common/MultiSelectBox";
import api from "../../services/api";
import Loading from "../../components/Loader/Loading";
import { Badge } from "../../components";
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from "react-icons/md";
import { toast } from "react-toastify";
import AutoComplete from "../../components/common/AutoComplete";
import debounce from "lodash/debounce";
import { useSelector } from "react-redux";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
const MoneyDomainReport = () => {
  const { user } = useSelector((state) => state.auth);
  const [backlinksTypeList, setBacklinksTypeList] = useState([]);
  const initalData = {
    domain: "",
    backLinkType: [],
    task_status: [],
    domainId: "",
  };
  const [selectedPayload, setSelectedPayload] = useState(initalData);
  const [isLoading, setIsLoading] = useState(false);
  const [isDomainData, setDomainData] = useState([]);
  const [isExpand, setExpandSection] = useState(null);
  const [filteredStatusOptions, setFilteredStatusOptions] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [isDomains, setDomains] = useState([]);
  const [isAutoComLoader, setAutoComLoader] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const statusOptions = [
    { value: "pending", label: "Pending" },
    { value: "inProcess", label: "In Process" },
    { value: "inReview", label: "In Review" },
    { value: "completed", label: "Completed" },
    { value: "hold", label: "Hold" },
    { value: "cancelled", label: "Cancelled" },
    { value: "reOpen", label: "Reopen" },
  ];

  const getBacklinkTypeList = () => {
    api
      .get(`/api/backlinks/backlink_type_list`)
      .then((res) => setBacklinksTypeList(res.data))
      .catch((err) => console.log(err));
  };
  const getDomainReport = async (param) => {
    setIsLoading(true);
    if (param) {
      setDomainData([]);
      setIsLoading(false);
      return;
    }
    const payload = {
      domain: selectedPayload?.domain,
      ...(selectedPayload?.task_status?.length && {
        task_status: selectedPayload?.task_status,
      }),
      ...(selectedPayload?.backLinkType?.length && {
        backlink_type: selectedPayload?.backLinkType,
      }),
    };
    try {
      const res = await api.post("/api/report/domains_report", payload);
      if (res.status === 200 || res.status === 201) {
        setDomainData(res?.data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getBacklinkTypeList();
  }, []);
  function addSpaceBeforeUpperCase(s) {
    return s && s?.replace(/([A-Z])/g, " $1")?.trim();
  }
  const statusCountMap = isDomainData?.reduce((acc, item) => {
    acc[item.task_status] = item.total_count;
    return acc;
  }, {});

  const groupData = (data) => {
    setExpandSection(false);
    if (!selectedPayload?.backLinkType?.length) {
      return [];
    }
    return data.reduce((acc, item) => {
      const { task_status } = item;
      if (!acc[task_status]) {
        acc[task_status] = [];
      }
      acc[task_status].push(item);
      return acc;
    }, {});
  };
  const calculateTotalCount = (status) => {
    return groupedData[status].reduce(
      (total, item) => total + item.total_count,
      0
    );
  };

  const getFilteredStatusOptions = (statusOptions, selectedPayload) => {
    if (selectedPayload?.task_status?.length) {
      return statusOptions.filter((option) =>
        selectedPayload.task_status.includes(option.value)
      );
    }
    return statusOptions;
  };

  useEffect(() => {
    if (isDomainData !== null) {
      const grouped = groupData(isDomainData);
      setGroupedData(grouped);

      const newFilteredOptions = getFilteredStatusOptions(
        statusOptions,
        selectedPayload
      );
      setFilteredStatusOptions(newFilteredOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainData]);

  const handleSubmit = async () => {
    await getDomainReport();
  };

  const resetData = () => {
    setSelectedPayload({
      backLinkType: [],
      task_status: [],
      domain: "",
    });
    setSelectedItem("");
    getDomainReport("reset");
  };

  const handleSelect = (item) => {
    setSelectedPayload({
      domain: item?.domain,
      domainId: item?._id,
    });
    setSelectedItem(item);
  };

  const fetchData = useCallback(async (searchQuery) => {
    if (searchQuery?.length === 0) {
      setDomains([]);
      return;
    }
    const payload = {
      user_id: user?.user?.id,
      is_assigned: true,
      sort_field: null,
      sort_by: null,
      filters: {
        groupOp: "AND",
        rules: [
          {
            field: "is_expired",
            op: "eq",
            data: false,
          },
          {
            field: "domain",
            op: "cn",
            data: searchQuery,
          },
        ],
      },
      selected_columns: "domain",
    };
    setAutoComLoader(true);
    try {
      const res = await api.post("/api/domains/user_domains", payload);
      if (res.status === 200 || res.status === 201) {
        setDomains(
          res?.data?.record?.data.map((item) => ({
            _id: item._id,
            domain: item.domain,
          }))
        );
      }
    } catch (err) {
      console.log(err);
    }
    setAutoComLoader(false);
  }, []);
  const handleQueryChange = useCallback(
    debounce((newQuery) => fetchData(newQuery), 500),
    []
  );
  const handleDomainPage = (task_status, backlink, count) => {
    if (!count) {
      return;
    }
    /*  navigate(`/reports/money_domain_reports`, {
      state: {
        task_status: task_status,
        domain_data: {
          domain: selectedPayload?.domain,
          _id: selectedPayload?.domainId,
        },
        backlink:
          backlinksTypeList.find((item) => item.type === backlink)?.id || "",
      },
    }); */
    const url = `/reports/money_domain_reports?task_status=${task_status}&domain=${
      selectedPayload?.domain
    }&backlink=${
      backlinksTypeList.find((item) => item.type === backlink)?.id || ""
    }`;
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="px-6 pt-4 ">
        <CustomBreadcrumbs
          crumbs={[
            { label: "Home", link: "/" },
            { label: "Money Domain Report", link: "" },
          ]}
        />
        {isLoading && <Loading />}
        <div className=" my-3 border rounded  overflow-y-auto min-h-[600px]">
          <div className="flex justify-between bg-white">
            <div className="p-3 flex items-center space-x-4 text-xl font-semibold capitalize">
              <span>Money Domain Report</span>
            </div>
          </div>

          <div className="grid grid-cols-5 gap-1 p-3 rounded ">
            <AutoComplete
              data={isDomains}
              idField="_id"
              valueField="domain"
              onSelect={handleSelect}
              onQueryChange={handleQueryChange}
              placeholder="Search domain..."
              setAutoComLoader={setAutoComLoader}
              isAutoComLoader={isAutoComLoader}
              selectedItem={selectedItem}
              extraClass="mt-1"
            />
            <MultiSelectBox
              placeholder="Search Task Status"
              options={statusOptions}
              optionLabel="label"
              optionValue="value"
              filter
              maxSelectedLabels={2}
              selectedData={selectedPayload?.task_status}
              selectLabel="Select Task Status"
              onChange={(val) => {
                setSelectedPayload({
                  ...selectedPayload,
                  task_status: val,
                });
              }}
              className="h-[40px] w-full !rounded-[4px] focus:!shadow-none border"
              panelClassName="custom-multiselect-panel"
            />
            <MultiSelectBox
              placeholder="Search Backlink Type"
              options={backlinksTypeList}
              optionLabel="type"
              optionValue="id"
              filter
              maxSelectedLabels={2}
              selectedData={selectedPayload?.backLinkType}
              selectLabel="Select Backlink Type"
              onChange={(val) => {
                setSelectedPayload({
                  ...selectedPayload,
                  backLinkType: val,
                });
              }}
              className="h-[40px] w-full !rounded-[4px] focus:!shadow-none border"
              panelClassName="custom-multiselect-panel"
            />
            <div className="flex mt-2 sm:mt-0 sm:ml-1">
              <button
                type="button"
                class="btnPrimary w-[100px] h-[40px] mt-1"
                onClick={() => handleSubmit()}
              >
                Search
              </button>
              <button
                type="button"
                class="btnPrimary bg-[#233b5b] text-white w-[100px] h-[40px] mt-1 ml-2"
                onClick={() => resetData()}
              >
                Reset
              </button>
            </div>
          </div>

          {!(groupedData && Object.keys(groupedData)?.length > 0) &&
          statusCountMap &&
          Object.keys(statusCountMap)?.length > 0 ? (
            <div className="relative overflow-x-auto shadow-md bg-white">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase dark:text-gray-400">
                  <tr className="border-b border-gray-200 dark:border-gray-700 font-semibold text-sm">
                    {filteredStatusOptions.map((option) => (
                      <th
                        key={option.value}
                        scope="col"
                        className={`px-6 py-3 bg-gray-50 dark:bg-gray-800`}
                      >
                        {option.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b border-gray-200 dark:border-gray-700">
                    {filteredStatusOptions.map((option) => (
                      <td
                        key={option.value}
                        className={`px-6 py-4 bg-gray-50 dark:bg-gray-800`}
                      >
                        <button
                          onClick={() =>
                            handleDomainPage(
                              option.value,
                              "",
                              statusCountMap[option.value]
                            )
                          }
                        >
                          {statusCountMap[option.value] ? (
                            <Badge value={statusCountMap[option.value]} />
                          ) : (
                            0
                          )}
                        </button>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div>
              {Object.keys(groupedData).map((status) => (
                <div
                  className={`relative overflow-x-auto sm:rounded-lg bg-white px-5 border-b border-gray-200 dark:border-gray-700 my-2 mx-2 ${
                    isExpand === status ? "border-b-0" : ""
                  }`}
                  key={status}
                >
                  <div
                    className="flex items-center justify-between w-full text-gray-600 dark:text-gray-400 cursor-pointer h-10 px-4 py-2 text-base  font-medium  mb-1"
                    onClick={() =>
                      setExpandSection(isExpand === status ? null : status)
                    }
                  >
                    <span className="capitalize">
                      {addSpaceBeforeUpperCase(status)} (
                      {calculateTotalCount(status)})
                    </span>
                    <span>
                      {isExpand === status ? (
                        <MdKeyboardArrowDown size={22} />
                      ) : (
                        <MdKeyboardArrowRight size={22} />
                      )}
                    </span>
                  </div>
                  {isExpand === status && (
                    <div className="border-t border-gray-200 dark:border-gray-700">
                      {groupedData[status].map((item, index) => (
                        <div
                          key={index}
                          className="px-6 py-2 text-gray-500 border-b border-gray-200 dark:border-gray-700"
                        >
                          {item.type} :{" "}
                          <button
                            onClick={() =>
                              handleDomainPage(
                                status,
                                item?.type,
                                item.total_count
                              )
                            }
                          >
                            {item.total_count ? (
                              <Badge value={item.total_count} />
                            ) : (
                              0
                            )}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          {!(statusCountMap && Object.keys(statusCountMap)?.length > 0) &&
            !(groupedData && Object.keys(groupedData)?.length > 0) && (
              <div
                className={`sm:rounded-lg bg-white text-center  my-2 mx-2 font-semibold flex items-center justify-center h-[40px]`}
              >
                <div className="">No Records.</div>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default MoneyDomainReport;
