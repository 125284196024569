import React, { useState, useRef } from "react";
import { Badge } from "../../components";
import { BsBrowserChrome } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { TablePagination } from "@mui/material";
import { TfiReload } from "react-icons/tfi";
import CustomFilters from "../../components/common/CustomFilters";
import userPermission from "../../utils/userPermission";
import { domainReset } from "../../store/slices/taskPrevDomainSlice";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
const TaskListHeader = ({
  filterButton,
  tasks,
  handleStopBrowser,
  is_browser_active,
  is_browser_domain,
  linkBuilderId,
  isLoading,
  linkBuilderUser,
  handleLinkBuilderUserTasksList,
  LinkBuilders,
  selectedStatus,
  setSelectedStatus,
  showFilters,
  setShowFilters,
  fetchTasksList,
  setFilterButton,
  fetchTasksWithOpenStatus,
  setFilters,
  paginationModel,
  setExpandedRows,
  setSelectedDomainId,
  setPaginationModel,
  filters,
  selectedDate,
  setSelectedDate,
}) => {
  const [taskStatus, setTaskStatus] = useState("");
  const [liveStatus, setLivetatus] = useState("");

  const [filerByDate, setFilterByDate] = useState("bdb.assigned_date");
  const TaskListFiltersRef = useRef(null);
  const dispatch = useDispatch();
  const taskStatusOptions = [
    { value: "pending", label: "Pending" },
    { value: "inProcess", label: "In Process" },
    { value: "hold", label: "Hold" },
    { value: "cancelled", label: "Cancelled" },
    { value: "completed", label: "Completed" },
    { value: "inReview", label: "In Review" },
    { value: "reOpen", label: "Reopen" },
  ];
  const liveStatusOptions = [
    { value: "live", label: "Live" },
    { value: "notLive", label: "Not Live" },
  ];
  const handleTopPageChange = (page) => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: +page + 1 });
    fetchTasksWithOpenStatus({
      page: +page + 1,
      perPage: paginationModel.pageSize,
      filters: filters,
      source: "filter",
    });
  };
  const handleTopPageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
    fetchTasksWithOpenStatus({
      page: 1,
      perPage: newPageSize,
      filters: filters,
      source: "filter",
    });
  };
  const applyFilters = () => {
    const rules = [];
    if (taskStatus)
      rules.push({ field: "task_status", op: "eq", data: taskStatus });
    if (liveStatus)
      rules.push({ field: "live_status", op: "eq", data: liveStatus });

    const filters = {
      groupOp: "AND",
      rules: rules,
    };
    taskStatus ? setFilterButton(taskStatus) : setFilterButton("");

    fetchTasksList({
      page: paginationModel?.page,
      perPage: paginationModel?.pageSize,
      filters: filters,
    });
  };
  const handleResetFilters = () => {
    setTaskStatus("");
    setLivetatus("");
    fetchTasksList({
      page: paginationModel?.page,
      perPage: paginationModel?.pageSize,
    });
  };
  const reloadTask = () => {
    setTaskStatus("");
    setLivetatus("");
    setFilterButton("");
    setExpandedRows({});
    dispatch(domainReset());
    fetchTasksList({
      page: paginationModel?.page,
      perPage: paginationModel?.pageSize,
    });
  };
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "AND",
    rules: [],
  });
  const columns = [
    {
      headerName: "Task Status",
      field: "task_status",
      type: "select",
      valueOptions: taskStatusOptions || [],
      //apiEndpoint: "/api/",
      //method: "get",
      label: "label",
      value: "value",
      additionalOperators: [],
    },
    {
      headerName: "Live Status",
      field: "live_status",
      type: "select",
      valueOptions: liveStatusOptions || [],
      //apiEndpoint: "/api/",
      // method: "post",
      label: "label",
      value: "value",
      payload: { page: 1, size: 1000 },
      additionalOperators: [],
    },
    /* {
      headerName: "To Date", //added for reference
      field: "to_date",
      type: "date",
      valueOptions: [],
    },
    {
      headerName: "Action count",  //added for reference
      field: "action_count",
      type: "number",
      valueOptions: [],
      additionalOperators: [],
    } */
  ];

  const handleUpdateFilters = (status) => {
    dispatch(domainReset());
    setQueryOptions({ groupOp: "AND", rules: [] });
    setExpandedRows({});
    setSelectedDomainId("");
    /*  if (filterButton === status) {
      return;
    } */
    setFilterButton(status);
    let newFilters;

    if (status === "") {
      // Clear all filters
      newFilters = {
        groupOp: "",
        rules: [],
      };
    } else {
      // Add a new filter based on the button clicked
      newFilters = {
        groupOp: "AND",
        rules: [
          {
            field: "task_status",
            op: "eq",
            data: status,
          },
        ],
      };
    }
    fetchTasksWithOpenStatus({
      page: paginationModel?.page,
      perPage: paginationModel?.pageSize,
      filters: newFilters,
      source: "filter",
    });

    // Instead of setFilterButton, update the filters state directly
    setFilters(newFilters);
  };
  const handleSearch = (param, statusClick) => {
    setExpandedRows({});
    setSelectedDomainId("");
    dispatch(domainReset());
    const payload = {
      groupOp: "AND",
      rules: [],
    };
    if (!statusClick) {
      if (selectedStatus && selectedStatus !== "") {
        payload.rules.push({
          field: "task_status",
          op: "eq",
          data: selectedStatus,
        });
      }
    } else {
      if (param) {
        payload.rules.push({
          field: "task_status",
          op: "eq",
          data: param,
        });
      }
    }
    if (selectedDate) {
      payload.rules.push({
        field: `DATE(${filerByDate})`,
        op: "eq",
        data: dayjs(selectedDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      });
    }
    setFilters(payload);
    fetchTasksWithOpenStatus({
      filters: payload,
      page: paginationModel?.page,
      perPage: paginationModel?.pageSize,
      source: "filter",
    });
  };

  const handleNewFilter = () => {
    dispatch(domainReset());
    setExpandedRows({});
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    setFilters(queryOptions);
    fetchTasksWithOpenStatus({
      page: 1,
      perPage: paginationModel?.pageSize,
      filters: queryOptions,
      source: "filter",
    });
  };
  const handleFilterChange = (data) => {
    setExpandedRows({});
    if (!data?.rules.length) {
      setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
      setFilters(null);
      fetchTasksWithOpenStatus({
        page: 1,
        perPage: paginationModel?.pageSize,
        filters: null,
        source: "filter",
      });
    } else {
      setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    }
    setQueryOptions({ ...data });
  };

  function getTaskResult(tasks, selectedStatus) {
    const matchedKey = Object.keys(tasks).find(
      (key) => key.split("_")[0] === selectedStatus
    );
    if (matchedKey) {
      return tasks[matchedKey];
    } else {
      const { count, linkbuilderTaskListByDomain, ...restOfData } = tasks;
      return Object.values(restOfData).reduce((acc, value) => acc + value, 0);
    }
  }
  const taskTotalCount = getTaskResult(tasks, selectedStatus);
  return (
    <>
      {linkBuilderId ? (
        <div className="bg-white my-2 border rounded">
          <div className="flex items-center px-2 py-2">
            <select
              disabled={isLoading}
              className="inputField disabled:cursor-not-allowed w-fit py-2"
              value={linkBuilderUser?.id}
              onChange={handleLinkBuilderUserTasksList}
            >
              {LinkBuilders.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
            <select
              disabled={isLoading}
              className="inputField disabled:cursor-not-allowed w-fit py-2 ml-2"
              value={selectedStatus}
              onChange={(e) => {
                setSelectedStatus(e.target.value);
                handleSearch(e.target.value, "handleSearch");
              }}
            >
              <option value="">Select</option>
              {taskStatusOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <input
              className="inputField disabled:cursor-not-allowed w-fit py-2 ml-2 h-[38px]"
              placeholder="Date"
              onChange={(e) => {
                setSelectedDate(e.target.value);
              }}
              name="DATE(bdb.assigned_date)"
              value={selectedDate}
              type="date"
            />
            <select
              id="dateFilter"
              name="filter_by_date"
              value={filerByDate}
              className="inputField disabled:cursor-not-allowed w-fit py-2 ml-2"
              onChange={(e) => {
                setFilterByDate(e.target.value);
              }}
            >
              <option value="">Filter by Date</option>
              <option value="bdb.assigned_date">Assigned Date</option>
              <option value="bdb.updated_at">Task Status Date</option>
            </select>
            <button className="btnPrimary ml-2" onClick={handleSearch}>
              Search
            </button>
            {/* <span className="font-bold text-gray-600 ml-auto text-sm">
              Total Count: {taskTotalCount || 0}
            </span> */}
          </div>
        </div>
      ) : (
        <div className="bg-white my-2 border rounded">
          <div className="flex justify-between items-center w-full text-sm my-2">
            <div className="flex flex-wrap space-x-1 mx-2">
              {userPermission("Total Tasks") ? (
                <button
                  onClick={() => handleUpdateFilters("")}
                  className={`btnTable ${
                    filterButton === "" ? "border-l-4 border-secondary bg-blue/10" : ""
                  }`}
                >
                  Total Tasks{" "}
                  <Badge
                    value={
                      tasks &&
                      tasks?.pending_tasks +
                        tasks?.inProcess_tasks +
                        tasks?.inReview_tasks +
                        tasks?.completed_tasks +
                        tasks?.hold_tasks +
                        tasks?.reOpen_tasks +
                        (tasks?.cancelled_tasks || 0)
                    }
                  />
                </button>
              ) : null}
              {userPermission("Pending") ? (
                <button
                  onClick={() => handleUpdateFilters("pending")}
                  className={` btnTable ${
                    filterButton === "pending"
                      ? "border-l-4 border-secondary bg-blue/10"
                      : ""
                  }`}
                >
                  Pending <Badge value={tasks?.pending_tasks} />
                </button>
              ) : null}
              {userPermission("In Process") ? (
                <button
                  onClick={() => handleUpdateFilters("inProcess")}
                  className={`btnTable ${
                    filterButton === "inProcess"
                      ? "border-l-4 border-secondary bg-blue/10"
                      : ""
                  }`}
                >
                  In Process <Badge value={tasks?.inProcess_tasks} />
                </button>
              ) : null}
              {userPermission("In Review") ? (
                <button
                  onClick={() => handleUpdateFilters("inReview")}
                  className={`btnTable ${
                    filterButton === "inReview"
                      ? "border-l-4 border-secondary bg-blue/10"
                      : ""
                  }`}
                >
                  In Review <Badge value={tasks?.inReview_tasks} />
                </button>
              ) : null}
              {userPermission("On Hold") ? (
                <button
                  onClick={() => handleUpdateFilters("hold")}
                  className={`btnTable ${
                    filterButton === "hold" ? "border-l-4 border-secondary bg-blue/10" : ""
                  }`}
                >
                  On hold <Badge value={tasks?.hold_tasks} />
                </button>
              ) : null}
              {userPermission("On Cancelled") ? (
                <button
                  onClick={() => handleUpdateFilters("cancelled")}
                  className={`btnTable ${
                    filterButton === "cancelled"
                      ? "border-l-4 border-secondary bg-blue/10"
                      : ""
                  }`}
                >
                  Cancelled <Badge value={tasks?.cancelled_tasks} />
                </button>
              ) : null}
              {userPermission("Completed") ? (
                <button
                  onClick={() => handleUpdateFilters("completed")}
                  className={`btnTable ${
                    filterButton === "completed"
                      ? "border-l-4 border-secondary bg-blue/10"
                      : ""
                  }`}
                >
                  Completed <Badge value={tasks?.completed_tasks} />
                </button>
              ) : null}
              <button
                onClick={() => handleUpdateFilters("reOpen")}
                className={`btnTable ${
                  filterButton === "reOpen" ? "border-l-4 border-secondary bg-blue/10" : ""
                }`}
              >
                Reopen <Badge value={tasks?.reOpen_tasks} />
              </button>
              {+is_browser_active && is_browser_domain ? (
                <button
                  onClick={handleStopBrowser}
                  className="btnTable bg-white dark:bg-blue dark:shadow-white/10 dark:shadow-md text-red-600 font-bold"
                  title="Stop Browser"
                >
                  <BsBrowserChrome size={16} className="mr-1" />
                  Stop Browser
                </button>
              ) : null}
             {/*  <button
                onClick={reloadTask}
                className="btnTable text-[#1976d2] font-medium"
                title="Reload"
              >
                <TfiReload className=" h-5 w-5 text-[#1976d2] font-bold" />
              </button> */}
              <CustomFilters
                handleFilterChange={handleFilterChange}
                handleNewFilter={handleNewFilter}
                filterModel={queryOptions}
                columns={columns}
              />
            </div>
            <div className="w-[400px]">
              <TablePagination
                showFirstButton={false}
                showLastButton={false}
                color="primary"
                shape="rounded"
                size="medium"
                count={tasks?.count || 0}
                page={paginationModel.page - 1 || 0}
                onPageChange={(e, page) => handleTopPageChange(page)}
                rowsPerPage={paginationModel.pageSize || 100}
                onRowsPerPageChange={(e) =>
                  handleTopPageSizeChange(e.target.value)
                }
                component="div"
                rowsPerPageOptions={[
                  25,
                  50,
                  75,
                  100,
                  250,
                  500,
                  1000,
                  1500,
                  2000,
                  {
                    label: "All",
                    value: tasks?.count || 0,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      )}
      {showFilters && (
        <div
          ref={TaskListFiltersRef}
          className="w-full bg-gray-50 dark:bg-gray-950  rounded p-3 mt-2 mb-2"
        >
          <div className="border-t border-gray-300 pb-0">
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 gap-x-4 gap-y-3 mt-4">
              <div className="mt-2">
                <label htmlFor="roleId" className="inputLabel">
                  Task Status:
                </label>
                <select
                  value={taskStatus}
                  onChange={(e) => setTaskStatus(e.target.value)}
                  name="task_status"
                  className={`inputField mt-1`}
                >
                  <option value="">Select</option>
                  {taskStatusOptions?.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mt-2">
                <label htmlFor="roleId" className="inputLabel">
                  Live Status:
                </label>
                <select
                  value={liveStatus}
                  onChange={(e) => setLivetatus(e.target.value)}
                  name="live_status"
                  className={`inputField mt-1`}
                >
                  <option value="">Select</option>
                  {liveStatusOptions?.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-2 mt-0">
            <button
              onClick={() => setShowFilters(false)}
              className="button btnPrimary bg-red-500"
            >
              Close
            </button>
            <button onClick={applyFilters} className="btnPrimary bg-secondary">
              <FaSearch className="w-4 h-4" /> Apply Filters
            </button>
            <button onClick={handleResetFilters} className="btnPrimary">
              Reset Filters
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default TaskListHeader;
