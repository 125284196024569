import React, { useState } from "react";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { XCircleIcon } from "@heroicons/react/24/solid";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

export default function Notes({
  task,
  addNewNote,
  setAddNewNote,
  notes,
  getNotes,
  newUpdatedTaskId,
}) {
  const { user } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  // Add Note
  const [note, setNote] = useState("");
  const addNote = (e) => {
    e.preventDefault();
    setIsLoading(true);
    api
      .post(`api/linkbuilder_user/task_note`, {
        task_id: task,
        user_id: user?.user?.id,
        note,
      })
      .then((res) => {
        toast.success(res.data.message);
        setNote("");
        setAddNewNote(false);
      })
      .catch((err) => {
        toast.error(
          err.response?.data?.message || err.data.message || "An error occurred"
        );
      })
      .finally(() => {
        getNotes(newUpdatedTaskId);
        setIsLoading(false);
      });
  };
  return (
    <div className="card px-2 mt-0">
      {addNewNote && (
        <div className="flex items-center justify-between gap-4 px-3 mb-5 ">
          <XCircleIcon
            onClick={() => setAddNewNote(false)}
            className="text-red-500 dark:text-red-400 w-8 cursor-pointer"
          />
          <textarea
            rows="1"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            className="inputField py-2 dark:bg-dark dark:border-white/50"
            placeholder="Write new note"
          ></textarea>
          <div className="flex items-center justify-end gap-2">
            <button
              onClick={() => setAddNewNote(false)}
              type="button"
              className="btnPrimary bg-red-500"
            >
              Cancel
            </button>
            {isLoading ? (
              <div className="bg-blue/10 flex items-center justify-center rounded-md py-2 w-48">
                <img src="/img/loading.gif" className="w-7" alt="" />
              </div>
            ) : (
              <button onClick={addNote} className="btnPrimary bg-green-600">
                Save
              </button>
            )}
          </div>
        </div>
      )}

      <div class="-m-1.0">
        <div class="p-1.0 min-w-full inline-block align-middle">
          <div class="overflow-hidden">
            <div class="table border-collapse table-auto w-full divide-y divide-gray-200">
              <div class="table-header-group border-b">
                <div class="table-row">
                  <div class="table-cell w-1/4 px-6 py-1 text-start text-sm font-medium text-gray-500 uppercase">
                    User
                  </div>
                  <div class="table-cell w-1/2 px-6 py-1 text-start text-sm font-medium text-gray-500 uppercase">
                    Description
                  </div>
                  <div class="table-cell w-1/4 px-6 py-1 text-start text-sm font-medium text-gray-500 uppercase">
                    Date
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-1.0 min-w-full inline-block align-middle overflow-auto max-h-[300px]">
          <div className="overflow-hidden">
            <div className="table border-collapse table-auto w-full divide-y divide-gray-200">
              {notes?.length ? (
                <div className="table-row-group divide-y divide-gray-200 bg-white">
                  {notes.map((item, index) => (
                    <div className="table-row" key={index}>
                      <div className="table-cell w-1/4 px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-800 md:px-6">
                        {item?.name}
                      </div>
                      <div className="table-cell w-1/2 px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-800 md:px-6">
                        {item?.note}
                      </div>
                      <div className="table-cell w-1/4 px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500 md:px-6">
                        {dayjs(item?.created_at)?.format(
                          "ddd, MMM D, YYYY h:mm A"
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="table-row text-center px-4 py-2 text-sm font-medium">
                  No Records.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
