import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import SearchBox from "../SearchBox";
import { useState } from "react";

const DataTable = ({
  heads,
  items,
  className,
  tableHeight,
  isLoading,
  totalRecords,
  startIndex,
  lastIndex,
  toNextPage,
  toPreviousPage,
  toFirstPage,
  toLastPage,
  nextPageDisabled,
  previousPageDisabled,
  tdClass,
  denseRow,
  title,
  searchBox,
  handleSubmit,
  extras,
  itemsPerPage,
  handlePerPageChange,
  enableAllRecords,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  return (
    <>
      <div className="flex items-center justify-between">
        {title && <h1 className="font-bold text-3xl capitalize">{title}</h1>}
        <div className="flex items-center justify-end gap-3">
          {extras}
          {searchBox && (
            <div className="relative w-fit">
              <SearchBox
                type="text"
                placeholder="Search..."
                className="border border-gray-300 py-[2px] dark:border-white/25 rounded-md bg-white dark:bg-blue w-fit"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              {searchQuery && (
                <button
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                  onClick={() => setSearchQuery("")}
                >
                  Clear
                </button>
              )}
            </div>
          )}
          {handleSubmit && (
            <>
              {isLoading ? (
                <div className="bg-blue/10 flex items-center justify-center rounded-md py-2 w-48">
                  <img src="/img/loading.gif" className="w-7" alt="" />
                </div>
              ) : (
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="btnPrimary bg-green-600"
                >
                  Save and Update
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <div
        className={`rounded w-full bg-white ${className ? '' : 'mt-5'} dark:bg-blue p-3 ${className}`}
      >
        <div
          className={`w-full overflow-y-scroll scroll-container ${
            totalRecords ? "h-[calc(100vh-235px)]" : "h-[calc(100vh-210px)]"
          } ${tableHeight}`}
        >
          <table className="min-w-full divide-y divide-gray-200 dark:divide-white/25">
            <thead className="bg-gray-20 bg-[#f8f8f8] dark:bg-white/10 backdrop-blur-md sticky top-0 shadow-sm">
              <tr>
                {heads?.map((head, key) => (
                  <th key={key} scope="col" className="tableHead">
                    {head}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200 dark:divide-white/40 h-full overflow-y-scroll  dark:text-white/70">
              {items
                ?.filter((item) =>
                  Object.values(item)?.some((value) =>
                    value
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(searchQuery.toLowerCase())
                  )
                )
                ?.map((item, index) => (
                  <tr
                    key={index}
                    className="hover:bg-gray-100 dark:hover:bg-white/10"
                  >
                    {Object.values(item).map((value, key) => (
                      <td
                        className={`px-3 ${denseRow ? "py-1" : "py-3"}`}
                        key={key}
                      >
                        <div className={`text-sm whitespace-nowrap ${tdClass}`}>
                          {value}
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
          {isLoading && (
            <div className="flex items-center justify-center py-10 w-full">
              <img src="/img/loading.gif" className="w-16" alt="" />
            </div>
          )}
        </div>
      </div>
      {totalRecords && (
        <div className="flex flex-col md:flex-row items-center justify-between mt-3">
          <div className="hidden md:flex item-center">
            Total Records: {totalRecords}
          </div>
          {startIndex && (
            <div className="flex items-center justify-end gap-2">
              {handlePerPageChange && (
                <select
                  className="inputField mr-6 px-2 py-1 dark:bg-transparent w-full md:w-fit dark:border-white/30 cursor-pointer rounded-md"
                  value={itemsPerPage}
                  onChange={handlePerPageChange}
                >
                  {/* <option className="dark:bg-blue">Items Per Page</option> */}
                  <option className="dark:bg-blue" value="25">
                    25
                  </option>
                  <option className="dark:bg-blue" value="50">
                    50
                  </option>
                  <option className="dark:bg-blue" value="100">
                    100
                  </option>
                  <option className="dark:bg-blue" value="200">
                    200
                  </option>
                  <option className="dark:bg-blue" value="300">
                    300
                  </option>
                  {enableAllRecords ? (
                    <option className="dark:bg-blue" value={totalRecords}>
                      All Records
                    </option>
                  ) : null}
                </select>
              )}
              {startIndex} - {lastIndex} of {totalRecords}
              {toFirstPage && (
                <button
                  type="button"
                  className="pagination ml-5"
                  onClick={toFirstPage}
                  disabled={previousPageDisabled}
                >
                  <FirstPage />
                </button>
              )}
              {toPreviousPage && (
                <button
                  type="button"
                  className="pagination"
                  onClick={toPreviousPage}
                  disabled={previousPageDisabled}
                >
                  <KeyboardArrowLeft />
                </button>
              )}
              {toNextPage && (
                <button
                  type="button"
                  className="pagination"
                  onClick={toNextPage}
                  disabled={nextPageDisabled}
                >
                  <KeyboardArrowRight />
                </button>
              )}
              {toLastPage && (
                <button
                  type="button"
                  className="pagination"
                  onClick={toLastPage}
                  disabled={nextPageDisabled}
                >
                  <LastPage />
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DataTable;
