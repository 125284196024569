import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { toast } from "react-toastify";
import { InputField } from "../../components";
import TagsInput from "../../components/CustomTagsInput";
import Loading from "../../components/Loader/Loading";
import { MultiSelect } from "primereact/multiselect";
export default function AddUpdateUserForm({
  handleModal,
  fetchUsersData,
  isLoading,
  setIsLoading,
  modalType,
  user,
  roles,
  merchantList,
}) {
  // New User
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [role_id, setRoleId] = useState([]);
  const [is_active, setIsActive] = useState(1);
  const [isAssignUserList, setAssignUserList] = useState([]);
  const [isChildUserList, setChildUserList] = useState([]);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [proxies, setProxies] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [merchant_id, setMerchantId] = useState("");
  const [merchants, setMerchants] = useState([]);
  const [proxy_id, setProxyId] = useState("");
  useEffect(() => {
    if (modalType === "editUser") {
      setName(user.name);
      setEmail(user.email);
      setUsername(user.username);
      setPassword(user.without_encrypted_password);
      setRoleId(user?.role_id);
      setMerchantId(user?.merchant_id);
      setMerchants(JSON.parse(user?.merchants));
      setIsActive(user?.is_active);
    } else if (modalType === "newUser") {
      setName("");
      setEmail("");
      setUsername("");
      setPassword("");
      setRoleId("");
      setMerchantId("");
      setMerchants("");
      setIsActive(1);
      setProxyId("");
    }
    getAssignedUsers();
    getChildUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalType, user]);
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Form validation
    const errors = {};

    if (!name.trim()) {
      errors.name = "Please enter the full name.";
    }

    if (!email.trim()) {
      errors.email = "Please enter the email.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Please enter a valid email address.";
    }

    if (!username.trim()) {
      errors.username = "Please enter the username.";
    }

    if (modalType === "newUser") {
      if (!password.trim()) {
        errors.password = "Please enter the password.";
      }
    }
    if (!role_id) {
      errors.role_id = "Please enter the role.";
    }
    if (!merchant_id) {
      errors.merchant_id = "Please select merchant.";
    }
    if (!merchants) {
      errors.merchants = "Please select atleast a single merchant";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setIsLoading(false);
      return;
    }

    const userData = {
      name,
      email,
      username,
      password,
      role_id,
      merchant_id: parseInt(merchant_id),
      merchants,
      is_active,
      ...(modalType === 'newUser' ? { proxy_id } : {}),
    };
    if (modalType === "newUser") {
      api
        .post(`${process.env.REACT_APP_PUBLIC_API}/api/users/addUser`, userData)
        .then(() => {
          handleModal();
          fetchUsersData();
          toast.success("New User Created!", { autoClose: 3000 });
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (modalType === "editUser" && user.id) {
      api
        .put(`${process.env.REACT_APP_PUBLIC_API}/api/users/updateUser`, {
          id: user.id,
          ...userData,
        })
        .then(() => {
          handleModal();
          fetchUsersData();
          toast.success("User updated successfully!", { autoClose: 3000 });
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const getAssignedUsers = async () => {
    setIsUserLoading(true);
    try {
      const response = await api.get(
        `${process.env.REACT_APP_PUBLIC_API}/api/users/list_parent_users?user_id=${user?.id}`
      );
      setTagData(response?.data.map((item) => item.name.trim()));
      await setAssignUserList(response.data);
      setIsUserLoading();
    } catch (error) {
      console.error(`Error fetching users data: ${error}`);
    }
  };
  const getChildUsers = async () => {
    setIsUserLoading(true);
    try {
      const response = await api.get(
        `${process.env.REACT_APP_PUBLIC_API}/api/users/list_sub_users?user_id=${user?.id}`
      );
      setChildUserList(response?.data.map((item) => item.name.trim()));
      setIsUserLoading();
    } catch (error) {
      console.error(`Error fetching users data: ${error}`);
    }
  };
  const multiloginProxies = async () => {
    try {
      const response = await api.get(
        `${process.env.REACT_APP_PUBLIC_API}/api/multilogin/proxies`
      );
      setProxies(response?.data);
    } catch (error) {
      console.error(`Error fetching proxies data: ${error}`);
    }
  };
  useEffect(() => {
    multiloginProxies();
  }, []);
  return (
    <div>
      {isUserLoading && modalType === "editUser" && <Loading />}
      <div className="grid md:grid-cols-2 gap-4 w-full">
        <InputField
          label="name"
          name="name"
          placeholder="Enter full name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={errors.name} // Pass the error prop for validation
          required={true}
        />
        <InputField
          label="email"
          name="email"
          placeholder="Enter user email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={errors.email} // Pass the error prop for validation
          required={true}
        />
        <InputField
          label="username"
          name="username"
          placeholder="Enter username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          disabled={modalType === "editUser"}
          error={errors.username} // Pass the error prop for validation
          required={true}
          autoComplete="new-username"
        />
        <InputField
          label="password"
          name="password"
          placeholder="Enter password"
          autoco
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={errors.password} // Pass the error prop for validation
          required={true}
          autoComplete="new-password"
        />

        <div className="mt-2">
          <label htmlFor="roleId" className="inputLabel">
            Select Role:
          </label>
          <select
            value={role_id}
            onChange={(e) => setRoleId(e.target.value)}
            name="role_id"
            className={`inputField mt-1 ${
              errors.level ? "border-red-500" : ""
            }`}
            error={errors.role_id}
          >
            <option value="">Select Role</option>
            {roles?.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </select>
          {errors.role_id && (
            <div className="text-red-500">{errors.role_id}</div>
          )}
        </div>
        <div className="mt-2">
          <label htmlFor="roleId" className="inputLabel">
            Primary Merchant:
          </label>
          <select
            value={merchant_id}
            onChange={(e) => setMerchantId(e.target.value)}
            name="merchant_id"
            className={`inputField mt-1 ${
              errors.level ? "border-red-500" : ""
            }`}
            error={errors.merchant_id}
          >
            <option value="">Select</option>
            {merchantList?.map(({ id, company_name }) => (
              <option key={id} value={id}>
                {company_name}
              </option>
            ))}
          </select>
          {errors.merchant_id && (
            <div className="text-red-500">{errors.merchant_id}</div>
          )}
        </div>
        <div className="mt-2">
          <label htmlFor="roleId" className="inputLabel">
            Select Merchants:
          </label>
          <MultiSelect
            placeholder="Select Merchants"
            options={merchantList}
            optionLabel="company_name"
            optionValue="id"
            filter
            maxSelectedLabels={2}
            value={merchants}
            onChange={(e) => setMerchants(e.value)}
            className="h-[40px] w-full !rounded-[4px] focus:!shadow-none border border-#e5e7eb-500 hover:border-gray-300"
            panelClassName="bg-gray-300 border border-gray-300"
          />
          {errors.merchants && (
            <div className="text-red-500">{errors.merchants}</div>
          )}
        </div>

        <div className="mt-2">
          <label htmlFor="roleId" className="inputLabel">
            Active:
          </label>
          <select
            value={is_active}
            onChange={(e) => setIsActive(e.target.value)}
            name="is_active"
            className={`inputField mt-1 ${
              errors.level ? "border-red-500" : ""
            }`}
            error={errors.is_active}
          >
            <option value={1}>Active</option>
            <option value={0}>In Active</option>
          </select>
          {errors.role_id && (
            <div className="text-red-500">{errors.role_id}</div>
          )}
        </div>
        {modalType === "editUser" && (
          <div className="mt-2">
            <label htmlFor="" className="inputLabel">
              Parent Users:
            </label>
            {isAssignUserList && isAssignUserList.length > 0 && (
              <div className="col-span-1 !my-3">
                <TagsInput
                  tags={tagData}
                  setTags={(tag) => setTagData(tag)}
                  disable
                />
              </div>
            )}
          </div>
        )}
        {modalType === "editUser" && (
          <div>
            <label htmlFor="" className="inputLabel">
              Child Users:
            </label>
            {isChildUserList && isChildUserList.length > 0 && (
              <div className="col-span-1 !my-3">
                <TagsInput
                  tags={isChildUserList}
                  setTags={(tag) => setChildUserList(tag)}
                  disable
                />
              </div>
            )}
          </div>
        )}
        {modalType === "newUser" && (
          <div className="mt-2">
            <label htmlFor="proxyId" className="inputLabel">
              Select Proxy:
            </label>
            <select
              value={proxy_id}
              onChange={(e) => setProxyId(e.target.value)}
              name="proxy_id"
              className={`inputField mt-1`}
              error={errors?.proxy_id}
            >
              <option value="">Select Proxy</option>
              {proxies?.map(({ id, proxy_ip }) => (
                <option key={id} value={id}>
                  {proxy_ip}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      <div className="flex items-cente justify-end mt-5 space-x-2 text-white">
        <button
          onClick={handleModal}
          type="button"
          className="btnPrimary bg-red-500"
        >
          Cancel
        </button>

        {isLoading ? (
          <div className="bg-blue/10 flex items-center justify-center rounded-md py-2 w-48">
            <img src="/img/loading.gif" className="w-7" alt="" />
          </div>
        ) : (
          <button
            type="button"
            onClick={handleSubmit}
            className="btnPrimary bg-green-600"
          >
            Save and Update
          </button>
        )}
      </div>
    </div>
  );
}
