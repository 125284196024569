import React from "react";
import { InputField, TextArea } from "../../../components";
const URLModal = ({
  onClose,
  link_to_website,
  setLinkToWebsite,
  updateStatus,
  taskObj,
  isStatusNote,
  setStatusNote,
  errors,
}) => {
  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center z-40 bg-black/60">
        <div className="bg-white p-4 rounded-lg shadow-md relative w-auto max-w-xl min-w-[400px]">
          <div className="text-base font-medium !mb-6">
            {taskObj &&
            (taskObj.status === "hold" || taskObj.status === "pending"  || taskObj.status ==="cancelled" || taskObj.status === "reOpen")
              ? "Please add a Note"
              : taskObj && taskObj.status === "completed"
              ? "Please paste the URL"
              : taskObj && taskObj.status === "inReview"
              ? "Please add a URL"
              : null}
          </div>
          {taskObj &&
            (taskObj.status === "hold" || taskObj.status === "pending" || taskObj.status === "cancelled" || taskObj.status === "reOpen") && (
              <div className="flex items-center grid grid-cols-1 gap-x-6">
                <TextArea
                  placeholder="Add Note"
                  className="w-56"
                  value={isStatusNote}
                  onChange={(e) => setStatusNote(e.target.value)}
                />
                {errors?.note_status && (
                  <div className="text-red-500">{errors?.note_status}</div>
                )}
              </div>
            )}
          {taskObj && taskObj.status === "inReview" && (
            <div className="flex items-center grid grid-cols-1 gap-x-6">
              <InputField
                placeholder="Add URL"
                className="w-56"
                value={link_to_website}
                onChange={(e) => setLinkToWebsite(e.target.value)}
              />
            </div>
          )}
          {taskObj && taskObj.status === "completed" && (
            <div className="flex items-center grid grid-cols-1 gap-x-6">
              <InputField
                placeholder="Paste URL"
                className="w-56"
                value={link_to_website}
                onChange={(e) => setLinkToWebsite(e.target.value)}
              />
            </div>
          )}
          <div className="flex justify-end !mt-4">
            <button
              className="px-4 py-2 mr-2 bg-secondary text-white rounded hover:bg-blue-600"
              onClick={() => {
                taskObj && taskObj?.rowId
                  ? updateStatus(taskObj?.rowId, taskObj?.status,taskObj?.prevStatus)
                  : updateStatus(taskObj?.status);
              }}
            >
              Confirm
            </button>
            <button
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default URLModal;
