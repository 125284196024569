import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { gridClasses } from "@mui/x-data-grid-pro";
import api from "../../services/api";
import { Modal } from "../../components";
import MUIDataTable from "../../components/MUIGrid";
import { Dropdown } from "primereact/dropdown";
import Loading from "../../components/Loader/Loading";
import dayjs from "dayjs";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Delete } from "@mui/icons-material";
import { Tooltip, LinearProgress, Box, Button } from "@mui/material";
import userPermission from "../../utils/userPermission";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import { useNavigate, Link } from "react-router-dom";
import { MdAddCircleOutline } from "react-icons/md";
import { useLocation } from "react-router-dom";
export default function Batches() {
  const location = useLocation();
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isDataModal, setDataModal] = useState(false);
  const [isDomainData, setDomainData] = useState("");
  const cancelButtonRef1 = useRef(false);
  const [isSelectedBatch, setSelectedBatch] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const handleDataModal = () => {
    setDataModal(!isDataModal);
  };

  const get_batches = () => {
    setIsLoading(true);
    api
      .post(`/api/domains/list_batches`)
      .then((response) => {
        setBatches(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    get_batches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const filters = {
      groupOp: "AND",
      rules: [
        { field: "is_active", op: "eq", data: 1 },
        { field: "role_id", op: "isAnyOf", data: [3, 8] },
      ],
    };
    api
      .post(`${process.env.REACT_APP_PUBLIC_API}/api/users/users_report`, {
        filters,
        page: 1,
        perPage: 1000,
      })
      .then((response) => {
        const capitalizedUsers = response.data?.records.map((user) => {
          return {
            ...user,
            name: user.name.replace(/\b\w/g, (c) => c.toUpperCase()),
          };
        });
        setUsers(capitalizedUsers);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  const handleAssignUser = ({ batch_id, user_id }) => {
    // Show a confirmation prompt
    const isConfirmed = window.confirm(
      "Are you sure you want to assign this user to the batch?"
    );
    if (!isConfirmed) {
      // If not confirmed, do nothing
      return;
    }
    const payload = {
      batch_id: batch_id,
      user_id: user_id,
    };
    console.log("🚀 ~ handleAssignUser ~ payload:", payload);
    setIsLoading(true);
    api
      .post(`/api/domains/assign_batch_to_user/`, payload)
      .then((response) => {
        if (response.status === 200) {
          get_batches();
        }
        toast.success("User assigned successfully");
      })
      .catch((error) => {
        toast.error("User couldn't be assigned");
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  };

  function separateWords(inputString) {
    return inputString
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .split(/(?=[A-Z])/)
      .join(" ");
  }

  const deleteBatch = (batchId) => {
    api
      .delete(`/api/domains/batches/${batchId}`)
      .then((res) => toast.success(res.data.message))
      .catch((res) => toast.error(res.response.data.message))
      .finally(() => get_batches());
  };
  const openDataModal = async (domainId, domain, slug) => {
    slug ? setSelectedBatch(domainId) : setSelectedBatch("");
    setIsLoading(true);
    try {
      const response = await api.post(
        `${process.env.REACT_APP_PUBLIC_API}/api/domains/batch_info/${domainId}?type=${domain}`
      );
      setDomainData(response?.data);
    } catch (error) {
      console.error(`Error fetching data: ${error}`);
    }
    setDataModal(true);
    setIsLoading(false);
  };
  const columnDefs = [
    { headerName: "Sr#", field: "counter", width: 60, filterable: false },
    {
      field: "name",
      headerName: "Assigned User",
      minWidth: 200,
      type: "singleSelect",
      getOptionValue: (value) => value?.id,
      getOptionLabel: (value) => value.name,
      valueOptions: users,
      renderCell: (params) =>
        params?.row?.user_id ? (
          <span>{params?.row?.name}</span>
        ) : userPermission("Assign User") ? (
          <Dropdown
            value={params?.row?.user_id}
            onChange={(e) => {
              handleAssignUser({
                batch_id: params?.row?.records?.id,
                user_id: e.value,
              });
            }}
            options={users}
            optionLabel="name"
            optionValue="id"
            placeholder="Select User"
            className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-gray-500 capitalize"
            filter
            panelClassName="dropdown-panel-batches w-[50px]"
          />
        ) : null,
      valueGetter: (params) => params.row.user_id,
      valueFormatter: (params) => params.value,
    },
    {
      headerName: "Status",
      field: "status",
      minWidth: 150,
      type: "singleSelect",
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        status: value?.value,
      }),
      renderCell: (params) => <>{separateWords(params.row.status)}</>,
      valueOptions: [
        { value: "Created", label: "Created" },
        { value: "AssignedToUser", label: "Assigned To User" },
        { value: "Completed", label: "Completed" },
      ],
    },
    {
      headerName: "Batch Number",
      field: "batch_number",
      flex: 1,
      minWidth: 120,
    },
    {
      headerName: "DC",
      field: "domain_count",
      width: 100,
      flex: 1,
      renderHeader: (params) => (
        <div className="flex justify- items-center w-full">
          <Tooltip
            title={
              <div className="flex flex-col text-sm w-[full]">Domain Count</div>
            }
          >
            <span>Domain Count</span>
          </Tooltip>
        </div>
      ),
      renderCell: (params) =>
        params?.row.domain_count > 0 ? (
          <span
            className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
            onClick={(e) => openDataModal(params?.row.id, "domain")}
          >
            {params?.row.domain_count ? params?.row.domain_count : "-"}
          </span>
        ) : (
          <span className="py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </span>
        ),
    },
    {
      headerName: "BC",
      field: "backlink_count",
      width: 100,
      flex: 1,
      renderHeader: (params) => (
        <div className="flex justify- items-center w-full">
          <Tooltip
            title={
              <div className="flex flex-col text-sm w-[full]">
                Backlink Count
              </div>
            }
          >
            <span>Backlink Count</span>
          </Tooltip>
        </div>
      ),
      renderCell: (params) =>
        params?.row.backlink_count > 0 ? (
          <span
            className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
            onClick={(e) => openDataModal(params?.row.id, "backlink", true)}
          >
            {params?.row.backlink_count ? params?.row.backlink_count : "-"}
          </span>
        ) : (
          <span className="py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </span>
        ),
    },
    // {
    //   headerName: "Status",
    //   field: "status",
    //   minWidth: 200,
    //   renderCell: (params) => <>{separateWords(params.row.status)}</>,
    // },
    {
      headerName: "Complete %",
      field: "completePercentage",
      flex: 1,
      minWidth: 120,
      filterable: false,
      renderHeader: (params) => (
        <div className="flex justify- items-center w-full">
          <Tooltip
            title={
              <div className="flex flex-col text-sm w-[full]">
                Complete Percentage
              </div>
            }
          >
            <span>Completed</span>
          </Tooltip>
        </div>
      ),
      renderCell: (params) => {
        const { task_info } = params?.row?.records || {};
        const totalSum = task_info.reduce((acc, task) => acc + task.totals, 0);
        const completedTask = task_info.find(
          (task) => task.task_status === "completed"
        );
        const completedPercentage = completedTask
          ? ((completedTask.totals / totalSum) * 100).toFixed(2)
          : "";
        return completedPercentage ? (
          <Box width="100%">
            <LinearProgress
              variant="determinate"
              value={Number(completedPercentage)}
            />
            {completedPercentage}%
          </Box>
        ) : (
          "0%"
        );
      },
    },
    {
      field: "assigned_date",
      headerName: "Assigned Date",
      width: 200,
      editable: false,
      filterable: false,
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 200,
      editable: false,
      filterable: false,
    },
    {
      headerName: "Action",
      field: "batch_detail",
      minWidth: 150,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className="flex items-center gap-3">
            {userPermission("Delete Batche") ? (
              <button onClick={() => deleteBatch(params.row.id)}>
                <Delete className="text-red-400" fontSize="small" />
              </button>
            ) : null}
          </div>
        );
      },
    },
  ];

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="mb-3 mt-3">
        <GridToolbarColumnsButton
          sx={{
            borderRadius: "6px",
            marginRight: "4px",
            border: "1px solid",
            borderColor: "#e8eaee",
            height: "26px",
            paddingLeft: 1,
            paddingRight: 1,
          }}
        />
        <GridToolbarDensitySelector
          sx={{
            borderRadius: "6px",
            marginRight: "4px",
            border: "1px solid",
            borderColor: "#e8eaee",
            height: "26px",
            paddingLeft: 1,
            paddingRight: 1,
          }}
        />
        <GridToolbarFilterButton
          sx={{
            borderRadius: "6px",
            marginRight: "4px",
            border: "1px solid",
            borderColor: "#e8eaee",
            height: "26px",
            paddingLeft: 1,
            paddingRight: 1,
          }}
          ref={setFilterButtonEl}
        />
        <Link to="/new_batches" style={{ textDecoration: "none" }}>
          <Button
            variant="text"
            startIcon={<MdAddCircleOutline size={16} />}
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              fontSize: "0.8125rem",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            Create Batch
          </Button>
        </Link>
      </GridToolbarContainer>
    );
  }

  const getRowClass = (params) => {
    if (params.row.status === "Completed") {
      return "bg-green-200 dark:bg-green-500/40";
    }
  };

  const assignBacklinks = (batchId) => {
    navigate(`/domains/assign-backlinks`, {
      state: {
        selectedBatch: batchId,
      },
    });
  };

  const operator = ({ operator, field }) => {
    const isSelect =
      columnDefs?.find((item) => item.field === field)?.type === "singleSelect";
    const isNumber =
      columnDefs?.find((item) => item.field === field)?.type === "number";
    const isDate =
      columnDefs?.find((item) => item.field === field)?.type === "date";
    return operator === "cn"
      ? "contains"
      : operator === "eq" && !isSelect && !isNumber
      ? "equals"
      : operator === "eq" && isSelect && !isNumber
      ? "is"
      : operator === "eq" && !isSelect && isNumber
      ? "="
      : operator === "not" && !isSelect && isNumber
      ? "!="
      : operator === "gt" && isDate
      ? "after"
      : operator === "gte" && isDate
      ? "onOrAfter"
      : operator === "lt" && isDate
      ? "before"
      : operator === "lte" && isDate
      ? "onOrBefore"
      : operator === "gt"
      ? ">"
      : operator === "gte"
      ? ">="
      : operator === "lt"
      ? "<"
      : operator === "lte"
      ? "<="
      : operator;
  };
  const [queryOptions, setQueryOptions] = useState({
    groupOp: "AND",
    rules: [
      ...(queryParams.get("user_id")
        ? [
            {
              field: "name",
              op: "is",
              data: parseInt(queryParams.get("user_id")),
            },
          ]
        : []),
    ],
  });
  const [filterModel, setFilterModel] = React.useState({
    items: queryOptions.rules?.map(({ field, op, data }) => ({
      field,
      operator: operator({ operator: op, field }),
      value: data,
    })),
    logicOperator: "and",
    quickFilterValues: [],
    quickFilterLogicOperator: "and",
  });

  const processFilters = (filters) => {
    return (filters || [])?.map((filter) => {
      if (
        filter.operator === "isAnyOf" &&
        filter.value?.length &&
        filter.value[0]?.includes(",")
      ) {
        return {
          ...filter,
          value: filter.value[0].split(",").map((item) => item.trim()),
        };
      }
      return filter;
    });
  };

  const onFilterChange = React.useCallback((filterModel) => {
    const item = processFilters(filterModel?.items || []);
    setFilterModel({
      ...filterModel,
      items: item,
    });
    let ruless = [];
    // eslint-disable-next-line array-callback-return
    processFilters(filterModel?.items)?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator === "onOrBefore"
              ? "lte"
              : rule.operator === "before"
              ? "lt"
              : rule.operator === "onOrAfter"
              ? "gte"
              : rule.operator === "after"
              ? "gt"
              : rule.operator,
          data: rule.value
            ? rule.value
            : rule.value === 0
            ? rule.value
            : rule.value === false
            ? rule.value
            : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel?.logicOperator?.toUpperCase() || "AND",
      rules: ruless,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="px-6 pt-4">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Batches", link: "" },
        ]}
      />
      <ToastContainer />

      <div className="bg-white my-3 border rounded">
        {isLoading && <Loading />}
        <MUIDataTable
          columnDefs={columnDefs}
          items={batches?.batchListData?.map((data, index) => {
            const {
              description,
              batch_number,
              domain_count,
              backlink_count,
              name,
              status,
              id,
              user_id,
              completePercentage,
              backlinks_names,
            } = data;
            const assigned_date = data.assigned_date
              ? dayjs(data.assigned_date).format("ddd, MMM D, YYYY h:mm A")
              : "Not Assigned";
            const created_at = dayjs(data.created_at).format(
              "ddd, MMM D, YYYY h:mm A"
            );
            return {
              records: data,
              counter: index + 1,
              description,
              batch_number,
              domain_count,
              backlink_count,
              name,
              status,
              id,
              user_id,
              completePercentage,
              assigned_date,
              created_at,
              backlinks_names,
            };
          })}
          totalItems={batches?.count}
          searchText={searchText}
          setSearchText={setSearchText}
          // paginationModel={paginationModel}
          CustomToolbar={CustomToolbar}
          pagination="No"
          height="88vh"
          searchable="No"
          getRowClass={getRowClass}
          sx={{
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
              {
                outline: "none",
              },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
              {
                outline: "none",
              },
          }}
          onFilterModelChange={onFilterChange}
          filterModel={filterModel}
          filterMode="client"
        />
      </div>

      <Modal
        open={!isDataModal}
        handleModal={handleDataModal}
        cancelButtonRef={cancelButtonRef1}
        className="max-w-2xl  min-h-[90vh]"
        title={
          isDomainData && isDomainData[0]?.domain ? "Domains" : "Backlinks"
        }
      >
        {isSelectedBatch ? (
          <div className="flex flex-row items-center w-full space-x-2">
            <button
              className="btnPrimary bg-green-600 ml-auto"
              onClick={() => {
                assignBacklinks(isSelectedBatch);
              }}
            >
              Add Backlink
            </button>
          </div>
        ) : null}
        <div className="lg:grid-cols-1 grid gap-3 border-b dark:bg-white/10 dark:rounded-md font-bold mt-0">
          <div className="flex items-center py-1 pr-5 justify-between"></div>
        </div>
        <div className="lg:grid-cols-1 grid grid-cols-1 gap-3 dark:text-white/70 mt-1">
          <div className="flex flex-col h-96 overflow-y-auto pt-1 border border-gray-200 border-t-0 text-sm">
            {isDomainData &&
              isDomainData[0]?.domain &&
              isDomainData.map((item, index) => (
                <p key={index} className="px-1 flex items-center">
                  <span className="text-left pr-2">{index + 1}.</span>{" "}
                  {item.domain}
                </p>
              ))}
            {isDomainData &&
              isDomainData[0]?.backlink_domain &&
              isDomainData.map((item, index) => (
                <p key={index} className="px-1 flex items-center">
                  <span className="text-left pr-2">{index + 1}.</span>{" "}
                  {item.backlink_domain}
                </p>
              ))}
          </div>
        </div>
      </Modal>
    </div>
  );
}
