import React, { useEffect, useState } from "react";
import { DataTable } from "../../components";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loader/Loading";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import { useDispatch } from "react-redux";
import { domainReset } from "../../store/slices/taskPrevDomainSlice";
import { FaPersonCirclePlus } from "react-icons/fa6";
export default function LinkBuildersReport() {
  const dispatch = useDispatch();
  const [LinkBuilders, setLinkBuilders] = useState([]);
  const [linkBuilderStatus, setLinkBuilderStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const defaultFilter = [{ field: "role_id", op: "isAnyOf", data: [3, 7, 8] }];
  const linkBuilderOptionStatus = [
    { value: 1, label: "Active" },
    { value: 0, label: "In Active" },
  ];
  const get_user = async (filter) => {
    setIsLoading(true);
    let payload = { ...filter };
    if (!payload.filters?.rules?.find(({ field }) => field === "role_id")) {
      payload.filters?.rules.push(defaultFilter[0]);
    }
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    delete payload?.pageSize;
    try {
      const res = await api.post("/api/users/users_report", payload);
      if (res.status === 200) {
        setLinkBuilders(res.data?.records);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };
  // const fetchLinkBuildersList = () => {
  //   setIsLoading(true);
  //   api
  //     .get(`/api/linkbuilder_user/`)
  //     .then((res) => setLinkBuilders(res.data))
  //     .catch((err) => console.log(err.data.message))
  //     .finally(() => setIsLoading(false));
  // };

  useEffect(() => {
    get_user({
      filters: { groupOp: "AND", rules: [{ field: "is_active", op: "eq", data: 1 }] },
      page: 1,
      perPage: 1000,
    });
    dispatch(domainReset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigate = useNavigate();
  const handleUserTask = (userId, filter) => {
    navigate(`/settings/link-builders/${userId}`, {
      state: {
        filter,
        taskDomainData: "",
      },
    });
  };

  const handleSearch = async () => {
    let payload;
    if (linkBuilderStatus) {
      payload = {
        groupOp: "AND",
        rules: [],
      };
    }

    if (linkBuilderStatus) {
      payload.rules.push({
        field: "is_active",
        op: "eq",
        data: linkBuilderStatus,
      });
    }
    get_user({
      filters: payload,
      page: 1,
      perPage: 1000,
    });
  };

  const handleAssignBatch = (userId) => {
    const url = new URL("/batches", window.location.origin);
    url.searchParams.append("user_id", userId);
    const tabName = `batchTab`;
    let existingTab = window.open("", tabName);
    if (!existingTab || existingTab.closed) {
      existingTab = window.open(url, tabName);
    } else {
      existingTab.location.href = url;
      existingTab.focus();
    }
  };
  return (
    <div className="px-6 pt-4">
      {isLoading && <Loading />}
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Linkbuilders Tasks Report", link: "" },
        ]}
      />
      <div className="bg-white my-2 border rounded">
        <div className="flex items-center px-2 py-2">
          <select
            disabled={isLoading}
            className="inputField disabled:cursor-not-allowed w-fit py-2"
            value={linkBuilderStatus || 1}
            onChange={(event) => setLinkBuilderStatus(event.target.value)}
          >
            <option key="" value="">
              Select
            </option>
            {linkBuilderOptionStatus.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <button className="btnPrimary ml-2" onClick={handleSearch}>
            Search
          </button>
          <button
            onClick={() => {
              setLinkBuilderStatus("");
              get_user({
                filters: { groupOp: "AND", rules: [{ field: "is_active", op: "eq", data: 1 }] },
                page: 1,
                perPage: 1000,
              });
            }}
            className="btnPrimary ml-1 bg-gray-500"
          >
            Reset
          </button>
        </div>
      </div>
      <DataTable
        className="mt-0"
        heads={[
          "Sr#",
          "Name",
          "Assign Batch",
          "Role",
          "Username",
          "Email",
          "total tasks",
          "pending",
          "in process",
          "in review",
          "on hold",
          "cancelled",
          "completed",
          "reopen",
        ]}
        items={LinkBuilders?.map((user, index) => {
          const { task_info } = user;
          const totalTask = (task_info || []).reduce((total, item) => {
            return total + item.totals;
          }, 0);
          const pending =
            task_info?.find(({ task_status }) => task_status === "pending")
              ?.totals || 0;
          const completed =
            task_info?.find(({ task_status }) => task_status === "completed")
              ?.totals || 0;
          const inProcess =
            task_info?.find(({ task_status }) => task_status === "inProcess")
              ?.totals || 0;
          const hold =
            task_info?.find(({ task_status }) => task_status === "hold")
              ?.totals || 0;
          const cancelled =
            task_info?.find(({ task_status }) => task_status === "cancelled")
              ?.totals || 0;
          const inReview =
            task_info?.find(({ task_status }) => task_status === "inReview")
              ?.totals || 0;
          const reOpen =
            task_info?.find(({ task_status }) => task_status === "reOpen")
              ?.totals || 0;
          return {
            serialnumber: index + 1,
            name: user["name"],
            AssignBatch: (
              <button
                onClick={() => handleAssignBatch(user.id)}
                className="w-full inline-flex items-center justify-center space-x-2"
              >
                <FaPersonCirclePlus className="w-5 h-5" />
                <span className="bg-[#93c5fd] text-center  py-1 px-2 !rounded hover:cursor-pointer ml-3 text-xs mr-2 ">Assign</span>
              </button>
            ),
            role: user["role"],
            username: user["username"],
            email: user["email"],
            totalTasks: (
              <button
                onClick={() => handleUserTask(user.id, "")}
                className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
              >
                {totalTask}
              </button>
            ),
            pending: (
              <button
                onClick={() => handleUserTask(user.id, "pending")}
                className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
              >
                {pending}
              </button>
            ),
            inProcess: (
              <button
                onClick={() => handleUserTask(user.id, "inProcess")}
                className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
              >
                {inProcess}
              </button>
            ),
            inReview: (
              <button
                onClick={() => handleUserTask(user.id, "inReview")}
                className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
              >
                {inReview}
              </button>
            ),
            onHold: (
              <button
                onClick={() => handleUserTask(user.id, "hold")}
                className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
              >
                {hold}
              </button>
            ),
            cancelled: (
              <button
                onClick={() => handleUserTask(user.id, "cancelled")}
                className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
              >
                {cancelled}
              </button>
            ),
            completed: (
              <button
                onClick={() => handleUserTask(user.id, "completed")}
                className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
              >
                {completed}
              </button>
            ),
            reOpen: (
              <button
                onClick={() => handleUserTask(user.id, "reOpen")}
                className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
              >
                {reOpen}
              </button>
            ),
          };
        })}
      />
    </div>
  );
}
